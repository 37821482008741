.page-container {
    min-height: 100vh;
    background-color: #000;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .content-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 5rem;
  }
  
  .page-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #fff;
  }
  
  .card {
    background: #111;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
  }
  
  .card:hover {
    transform: translateY(-4px);
  }