.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #111;
    border-radius: 12px;
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    min-height: 500px;
  }
  
  .modal-content h2 {
    color: #ffd700;
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    color: #fff;
    margin-bottom: 0.5rem;
  }
  
  .form-select {
    width: 100%;
    padding: 0.75rem;
    background: #1a1a1a;
    border: 1px solid #333;
    border-radius: 6px;
    color: #fff;
    font-size: 1rem;
  }
  
  .modal-actions {
    margin-top: auto;
    padding-top: 20px;
    display: flex;
    gap: 10px;
  }
  
  .cancel-button, .generate-button {
    flex: 1;
    padding: 0.75rem;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cancel-button {
    border: 1px solid #333;
    background: transparent;
    color: #fff;
  }
  
  .cancel-button:hover {
    background: #1a1a1a;
  }
  
  .generate-button {
    background: #2563eb;
    color: #fff;
    border: none;
  }
  
  .generate-button:hover {
    background: #1d4ed8;
  }
  
  .generate-button:disabled {
    background: #1a1a1a;
    cursor: not-allowed;
  }
  
  .proxy-result {
    margin: 1.5rem 0;
  }
  
  .proxy-box {
    background: #1a1a1a;
    padding: 1rem;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
  }
  
  .proxy-box code {
    font-family: monospace;
    color: #4ade80;
    font-size: 0.9rem;
    word-break: break-all;
  }
  
  .copy-button {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    padding: 0.5rem;
    transition: color 0.2s ease;
    position: relative;
  }
  
  .copy-button:hover {
    color: #fff;
  }
  
  .copied-tooltip {
    position: absolute;
    bottom: 100%;
    right: 0;
    background: #4ade80;
    color: #064e3b;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    animation: fadeIn 0.2s ease;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(5px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .bandwidth-info {
    margin: 15px 0;
    padding: 15px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .bandwidth-text {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .bandwidth-bar {
    height: 6px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    overflow: hidden;
  }
  
  .bandwidth-progress {
    height: 100%;
    background: #3498db;
    border-radius: 3px;
    transition: width 0.3s ease;
  }
  
  .server-locations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .server-location {
    padding: 0.75rem;
    background: #1a1a1a;
    border: 1px solid #333;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .server-location.selected {
    background: #2563eb;
    border-color: #2563eb;
  }
  
  .server-location:hover:not(.selected) {
    background: #262626;
  }
  
  .form-select {
    width: 100%;
    padding: 8px 12px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    color: #fff;
    margin-top: 5px;
  }
  
  .ip-switch-container {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .ip-input {
    width: 100%;
    padding: 0.75rem;
    background: #1a1a1a;
    border: 1px solid #333;
    border-radius: 6px;
    color: #fff;
    font-size: 1rem;
  }
  
  .switch-ip-button {
    padding: 0.75rem 1rem;
    background: #2563eb;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
  }
  
  .switch-ip-button:hover {
    background: #1d4ed8;
  }
  
  .switch-ip-button:disabled {
    background: #1a1a1a;
    cursor: not-allowed;
  }
  
  .lock-message {
    color: #888;
    font-size: 0.9rem;
    margin: 0.5rem 0 0;
  }