.contact-hero-section {
  text-align: center;
  padding: 8rem 2rem 4rem;
  background: linear-gradient(to bottom, transparent, rgba(255, 215, 0, 0.05));
}

.contact-hero-section h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #fff, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-hero-section p {
  font-size: 1.2rem;
  color: #888;
}

.contact-methods {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.contact-card {
  background: rgba(17, 17, 17, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2.5rem;
  text-align: center;
  transition: transform 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-5px);
}

.contact-icon {
  font-size: 3rem;
  color: #ffd700;
  margin-bottom: 1.5rem;
}

.contact-card h3 {
  font-size: 1.5rem;
  color: #ffd700;
  margin-bottom: 1rem;
}

.contact-card p {
  color: #888;
  margin-bottom: 2rem;
}

.contact-button {
  display: inline-block;
  padding: 1rem 2rem;
  background: transparent;
  border: 2px solid #ffd700;
  color: #ffd700;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.contact-button:hover {
  background: #ffd700;
  color: #000;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .contact-hero-section h1 {
    font-size: 2.5rem;
  }
  
  .contact-methods {
    padding: 2rem;
  }
} 