.auth-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative;
  overflow: hidden;
}

/* Add orbital graphics like landing page */
.auth-orbital {
  position: absolute;
  width: 400px;
  height: 400px;
  border: 1px solid rgba(255, 215, 0, 0.1);
  border-radius: 50%;
  top: 50%;
  right: -100px;
  transform: translateY(-50%);
  animation: rotate 20s linear infinite;
}

.auth-orbital-inner {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid rgba(255, 215, 0, 0.2);
  border-radius: 50%;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  animation: rotate 15s linear infinite reverse;
}

.auth-header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.auth-logo {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.2s ease;
}

.auth-logo:hover {
  color: #ffd700;
}

.auth-content {
  width: 100%;
  max-width: 400px;
  margin-top: 2rem;
}

.auth-card {
  background: rgba(17, 17, 17, 0.95);
  border-radius: 12px;
  padding: 2.5rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.auth-card h2 {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: center;
  background: linear-gradient(45deg, #fff, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.auth-subtitle {
  color: #888;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  line-height: 1.5;
}

.form-group {
  margin-bottom: 16px;
  width: 100%;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  color: #fff;
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: #ffd700;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.1);
}

.form-input::placeholder {
  color: #666;
}

.auth-button {
  width: 100%;
  padding: 0.75rem;
  background: #ffd700;
  color: #000;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1.5rem;
  transition: all 0.2s ease;
}

.auth-button:hover {
  background: #ffed4a;
  transform: translateY(-2px);
}

.auth-footer {
  text-align: center;
  margin-top: 1.5rem;
  color: #888;
  font-size: 0.9rem;
}

.auth-link {
  color: #ffd700;
  text-decoration: none;
  margin-left: 0.5rem;
}

.auth-link:hover {
  text-decoration: underline;
}

.confirmation-text {
  text-align: center;
  color: #888;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.confirmation-text strong {
  color: #fff;
  font-weight: 500;
}

.error-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #ff4444;
  color: white;
  padding: 15px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
}

.error-popup button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 0 5px;
}

.error-popup button:hover {
  opacity: 0.8;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.password-group {
  position: relative;
  width: 100%;
}

.password-group .form-input {
  width: 100%;
  padding: 12px;
  padding-right: 40px;
  box-sizing: border-box;
}

.password-toggle {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-calc(-50% + 10px));
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
}

.password-toggle:hover {
  color: rgba(255, 255, 255, 0.8);
}

.password-toggle svg {
  width: 16px;
  height: 16px;
  display: block;
}

@keyframes rotate {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}