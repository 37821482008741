.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1000;
  padding: 1rem 0;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: #ffd700;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 700;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 2rem;
  height: 36px;
  line-height: 36px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.2s ease;
}

.nav-links a:hover {
  color: #ffd700;
}

.nav-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;
  height: 36px;
  line-height: 36px;
}

.icon-button {
  padding: 0.5rem;
  border-radius: 6px;
  color: #fff;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ffd700;
}

.auth-button {
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  white-space: nowrap;
  line-height: 1;
  margin-top: 0;
}

.login {
  color: #ffd700;
  border: 1px solid #ffd700;
  background: transparent;
}

.login:hover {
  background: rgba(255, 215, 0, 0.1);
}

.signup {
  background: #ffd700;
  color: #000 !important;
  border: 1px solid #ffd700;
  text-decoration: none;
}

.signup:hover {
  background: #ffed4a;
  border-color: #ffed4a;
  transform: translateY(-2px);
}

.logout {
  background: transparent;
  border: 1px solid #ff4444;
  color: #ff4444;
  cursor: pointer;
  margin: 0;
  line-height: 1;
}

.logout:hover {
  background: rgba(255, 68, 68, 0.1);
}

.mobile-menu {
  display: none;
  position: relative;
}

.menu-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem;
  display: none;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  padding: 1rem;
  border-radius: 8px;
  min-width: 200px;
  z-index: 1001;
}

.dropdown-menu.active {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 768px) {
  .menu-button {
    display: block;
  }

  .mobile-menu {
    display: block;
  }

  .nav-links {
    display: none;
  }

  .dropdown-menu a,
  .dropdown-menu button {
    padding: 0.5rem;
    width: 100%;
    text-align: left;
    color: #fff;
    text-decoration: none;
    transition: color 0.2s ease;
  }

  .dropdown-menu .auth-button {
    margin: 0;
    width: 100%;
    justify-content: flex-start;
  }

  .dropdown-menu .icon-button {
    padding: 0.5rem;
    width: 100%;
    justify-content: flex-start;
    gap: 0.5rem;
  }
}