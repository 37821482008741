.faq-hero-section {
  text-align: center;
  padding: 8rem 2rem 4rem;
  background: linear-gradient(to bottom, transparent, rgba(255, 215, 0, 0.05));
}

.faq-hero-section h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #fff, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faq-hero-section p {
  font-size: 1.2rem;
  color: #888;
  margin-bottom: 2rem;
}

.faq-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
}

.faq-item {
  background: rgba(17, 17, 17, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
  transition: transform 0.3s ease;
}

.faq-item:hover {
  transform: translateY(-5px);
}

.faq-item h3 {
  color: #ffd700;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.faq-item p {
  color: #888;
  line-height: 1.6;
}

.faq-cta {
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(to top, transparent, rgba(255, 215, 0, 0.05));
}

.faq-cta h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.faq-cta p {
  font-size: 1.2rem;
  color: #888;
  margin-bottom: 2rem;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.primary-button {
  display: inline-block;
  padding: 1rem 2rem;
  background: #ffd700;
  color: #000;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.primary-button:hover {
  transform: translateY(-2px);
  background: #ffed4a;
}

@media (max-width: 768px) {
  .faq-hero-section h1 {
    font-size: 2.5rem;
  }
  
  .faq-grid {
    grid-template-columns: 1fr;
  }
  
  .faq-content {
    padding: 2rem 1rem;
  }
} 