.loading-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 215, 0, 0.1);
  border-radius: 50%;
  border-top-color: #ffd700;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-content {
  text-align: center;
  position: relative;
}

.loading-graphics {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.loading-graphics .orbit-circle,
.loading-graphics .orbit-circle-inner {
  position: absolute;
  border: 2px solid rgba(255, 215, 0, 0.2);
  border-radius: 50%;
  animation: rotate 20s linear infinite;
}

.loading-graphics .orbit-circle {
  width: 300px;
  height: 300px;
}

.loading-graphics .orbit-circle-inner {
  width: 200px;
  height: 200px;
  top: 50px;
  left: 50px;
  animation-direction: reverse;
}

.loading-text {
  margin-top: 2rem;
}

.loading-text h2 {
  font-size: 2.5rem;
  background: linear-gradient(to right, #fff, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.loading-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.loading-dots span {
  width: 8px;
  height: 8px;
  background: #ffd700;
  border-radius: 50%;
  animation: dots 1.5s infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes dots {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}

@media (max-width: 768px) {
  .loading-graphics {
    width: 200px;
    height: 200px;
  }
  
  .loading-graphics .orbit-circle {
    width: 200px;
    height: 200px;
  }
  
  .loading-graphics .orbit-circle-inner {
    width: 140px;
    height: 140px;
    top: 30px;
    left: 30px;
  }
  
  .loading-text h2 {
    font-size: 2rem;
  }
} 