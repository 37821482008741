.page-container {
  min-height: 100vh;
  background: #000;
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 5rem;
}

.profile-section {
  background: #111;
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 2rem;
}

.profile-info {
  display: grid;
  gap: 1rem;
}

.profile-field {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profile-label {
  font-weight: 600;
  color: #888;
  min-width: 100px;
}

.clothing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.clothing-item {
  background: #111;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s ease;
}

.clothing-item:hover {
  transform: translateY(-4px);
}

.clothing-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.clothing-details {
  padding: 1rem;
}

.style-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.style-tag {
  background: #222;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.9rem;
}

.edit-styles-button,
.choose-styles-button {
  background: #333;
  border: none;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease;
}

.edit-styles-button:hover,
.choose-styles-button:hover {
  background: #444;
}

.choose-styles-button {
  background: #ffd700;
  color: #000;
}

.choose-styles-button:hover {
  background: #ffed4a;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.stats-card {
  background: #111;
  border-radius: 12px;
  padding: 2rem;
}

.stats-card h3 {
  color: #ffd700;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #222;
}

.stat-row:last-child {
  border-bottom: none;
}

.history-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.history-card {
  background: #111;
  border-radius: 12px;
  padding: 1.5rem;
  display: grid;
  gap: 0.5rem;
}

.purchase-type {
  color: #ffd700;
  font-weight: 600;
  font-size: 1.1rem;
}

.purchase-amount {
  color: #fff;
  font-size: 1.25rem;
}

.purchase-date {
  color: #888;
  font-size: 0.9rem;
}

.purchase-price {
  color: #4ade80;
  font-weight: 600;
  font-size: 1.1rem;
  margin-top: 0.5rem;
}