.proxy-card {
    background: rgba(17, 17, 17, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 2.5rem;
    transition: transform 0.2s ease;
  }
  
  .proxy-card:hover {
    transform: translateY(-5px);
  }
  
  .proxy-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
  }
  
  .proxy-header h3 {
    color: #ffd700;
    font-size: 2rem;
    font-weight: 600;
  }
  
  .proxy-badge {
    background: rgba(255, 215, 0, 0.1);
    color: #ffd700;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    border: 1px solid rgba(255, 215, 0, 0.2);
  }
  
  .proxy-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 2.5rem;
  }
  
  .stat-item {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .stat-label {
    color: #888;
    font-size: 1rem;
  }
  
  .stat-value {
    color: #fff;
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  .status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .status-badge.active {
    background: #4ade80;
    color: #064e3b;
  }
  
  .status-badge.inactive {
    background: #ef4444;
    color: #7f1d1d;
  }
  
  .proxy-details {
    margin-bottom: 2.5rem;
  }
  
  .detail-row {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .detail-row:last-child {
    border-bottom: none;
  }
  
  .detail-row span:first-child {
    color: #888;
    font-size: 1rem;
  }
  
  .detail-row span:last-child {
    color: #fff;
    font-weight: 500;
  }
  
  .purchase-button {
    width: 100%;
    background: transparent;
    color: #ffd700;
    padding: 1.2rem;
    border: 2px solid #ffd700;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .purchase-button:hover {
    background: #ffd700;
    color: #000;
    transform: translateY(-2px);
  }
  
  .generate-button {
    width: 100%;
    background: #2563eb;
    color: #fff;
    padding: 1.2rem;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: all 0.2s ease;
  }
  
  .generate-button:hover {
    background: #1d4ed8;
    transform: translateY(-2px);
  }