.pricing-hero-section {
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(to bottom, transparent, rgba(255, 215, 0, 0.05));
}

.pricing-hero-section h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #fff, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pricing-hero-section p {
  font-size: 1.2rem;
  color: #888;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.pricing-card {
  background: rgba(17, 17, 17, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
  transition: transform 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.pricing-card.featured {
  border: 1px solid #ffd700;
  background: rgba(17, 17, 17, 0.98);
  transform: scale(1.05);
}

.pricing-card.featured:hover {
  transform: scale(1.05) translateY(-5px);
}

.pricing-header {
  text-align: center;
  margin-bottom: 2rem;
}

.pricing-header h3 {
  font-size: 1.5rem;
  color: #ffd700;
  margin-bottom: 1rem;
}

.price {
  font-size: 2.5rem;
  color: #fff;
}

.price .period {
  font-size: 1rem;
  color: #888;
}

.pricing-features {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

.pricing-features li {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
  color: #fff;
}

.pricing-features li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: #ffd700;
}

.pricing-cta {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  background: transparent;
  border: 2px solid #ffd700;
  color: #ffd700;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pricing-cta:hover {
  background: #ffd700;
  color: #000;
}

.pricing-cta.featured {
  background: #ffd700;
  color: #000;
}

.pricing-cta.featured:hover {
  background: transparent;
  color: #ffd700;
}

.pricing-faq {
  padding: 6rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.pricing-faq h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #fff;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.faq-item {
  background: rgba(17, 17, 17, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.faq-item h3 {
  color: #ffd700;
  margin-bottom: 1rem;
}

.faq-item p {
  color: #888;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .pricing-hero-section h1 {
    font-size: 2.5rem;
  }

  .pricing-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .pricing-card.featured {
    transform: none;
  }

  .pricing-card.featured:hover {
    transform: translateY(-5px);
  }
}

.proxy-pricing-section {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.proxy-pricing-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #ffd700;
}

.section-subtitle {
  text-align: center;
  color: #888;
  font-size: 1.2rem;
  margin-bottom: 3rem;
}

/* Add margin between proxy sections */
.proxy-pricing-section + .proxy-pricing-section {
  margin-top: 2rem;
  padding-top: 6rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.billing-toggle-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.billing-toggle {
  background: rgba(17, 17, 17, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 4px;
  position: relative;
  display: flex;
  gap: 4px;
  width: 240px;
}

.toggle-slider {
  position: absolute;
  width: calc(50% - 4px);
  height: calc(100% - 8px);
  background: #ffd700;
  border-radius: 6px;
  transition: transform 0.3s ease;
  top: 4px;
  left: 4px;
}

.toggle-slider[data-active="month"] {
  transform: translateX(100%);
}

.billing-toggle button {
  flex: 1;
  background: transparent;
  border: none;
  padding: 0.75rem 1.5rem;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;
}

.billing-toggle button.active {
  color: #000;
}

.billing-toggle button:not(.active) {
  color: #fff;
} 