.purchase-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 5rem;
  }
  
  .purchase-card {
    background: #111;
    border-radius: 12px;
    padding: 2rem;
    margin-top: 2rem;
  }
  
  .amount-selector {
    margin-bottom: 2rem;
  }
  
  .amount-selector label {
    display: block;
    color: #fff;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  .amount-value {
    margin-left: 1rem;
    color: #ffd700;
    font-weight: 600;
  }
  
  .amount-slider {
    width: 100%;
    height: 6px;
    background: #333;
    border-radius: 3px;
    -webkit-appearance: none;
  }
  
  .amount-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #ffd700;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .price-display {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .price-label {
    font-size: 1.2rem;
    color: #888;
    margin-right: 1rem;
  }
  
  .price-value {
    font-size: 2rem;
    color: #ffd700;
    font-weight: 700;
  }
  
  .purchase-button {
    width: 100%;
    background: #ffd700;
    color: #000;
    padding: 1rem;
    border: none;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .purchase-button:hover {
    background: #ffed4a;
  }
  
  .purchase-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .rotation-selector {
    margin-bottom: 2rem;
  }
  
  .rotation-selector label {
    display: block;
    color: #fff;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  .rotation-options {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .rotation-option {
    background: #222;
    border: 1px solid #333;
    border-radius: 6px;
    padding: 1rem;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
    font-size: 1.1rem;
  }
  
  .rotation-option:hover {
    background: #2a2a2a;
  }
  
  .rotation-option.selected {
    background: #ffd700;
    color: #000;
    border-color: #ffd700;
  }
  
  .amount-value.editable {
    background: transparent;
    border: none;
    color: #ffd700;
    font-weight: 600;
    font-size: inherit;
    width: 60px;
    margin-left: 1rem;
    padding: 0;
    text-align: left;
  }
  
  .amount-value.editable:focus {
    outline: none;
    border-bottom: 2px solid #ffd700;
  }
  
  .amount-value.editable::-webkit-inner-spin-button,
  .amount-value.editable::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .plan-toggle-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .plan-toggle {
    background: rgba(17, 17, 17, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 4px;
    position: relative;
    display: flex;
    gap: 4px;
    width: 360px;
  }
  
  .plan-toggle .toggle-slider {
    position: absolute;
    width: calc(33.33% - 4px);
    height: calc(100% - 8px);
    background: #ffd700;
    border-radius: 6px;
    transition: transform 0.3s ease;
    top: 4px;
    left: 4px;
  }
  
  .plan-toggle .toggle-slider[data-active="basic"] {
    transform: translateX(0);
  }
  
  .plan-toggle .toggle-slider[data-active="pro"] {
    transform: translateX(100%);
  }
  
  .plan-toggle .toggle-slider[data-active="enterprise"] {
    transform: translateX(200%);
  }
  
  .plan-toggle button {
    flex: 1;
    background: transparent;
    border: none;
    padding: 0.75rem 1.5rem;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: color 0.3s ease;
  }
  
  .plan-toggle button.active {
    color: #000;
  }
  
  .plan-toggle button:not(.active) {
    color: #fff;
  }
  
  .plan-details {
    text-align: center;
    margin: 20px 0;
    padding: 15px;
    background: #2a2a2a;
    border-radius: 10px;
  }