.settings-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 5rem;
    overflow: hidden;
  }
  
  .settings-card {
    background: #111;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
  }
  
  .settings-section {
    margin-bottom: 2rem;
  }
  
  .section-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #fff;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
    color: #fff;
  }
  
  .form-input {
    width: 100%;
    padding: 0.75rem;
    background: #222;
    border: 1px solid #333;
    border-radius: 6px;
    color: #fff;
    font-size: 1rem;
  }
  
  .form-input:focus {
    outline: none;
    border-color: #ffd700;
  }
  
  .save-button {
    background: #ffd700;
    color: #000;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 1rem;
  }
  
  .save-button:hover {
    background: #ffed4a;
  }
  
  .password-input-container {
    position: relative;
    width: 100%;
  }
  
  .password-toggle-button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .password-toggle-button:hover {
    color: #ffd700;
  }
  
  .danger-zone {
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.2);
    border-radius: 8px;
    padding: 1.5rem;
  }
  
  .danger-title {
    color: #ff4444;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .delete-button {
    background: #ff4444;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .delete-button:hover {
    background: #ff6666;
  }