.email-confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .email-confirmation-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .email-address {
    font-weight: bold;
    color: #0066cc;
    margin: 1rem 0;
  }
  
  .confirm-button {
    background-color: #0066cc;
    color: white;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .confirm-button:hover {
    background-color: #0052a3;
  }