.dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 5rem;
}

.proxy-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.proxy-stat-card {
  background: #111;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.2s ease;
}

.proxy-stat-card:hover {
  transform: translateY(-4px);
}

.proxy-stat-card h3 {
  color: #ffd700;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.stat-value {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: #888;
  font-size: 1.1rem;
}

.dashboard-hero-section {
  padding: 4rem 2rem;
  text-align: center;
  background: linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.6));
}

.dashboard-hero-section h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #fff, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard-hero-section p {
  font-size: 1.1rem;
  color: #888;
}

.dashboard-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  min-height: calc(100vh - 600px); /* Ensures footer stays at bottom */
}

.proxy-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin-top: 2rem;
  margin-bottom: 4rem; /* Add space before footer */
}

.proxy-grid h2 {
  font-size: 2rem;
  color: #ffd700;
  margin-bottom: 0.5rem;
}

.section-subtitle {
  color: #888;
  margin-bottom: 2rem;
}

.proxy-section {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.proxy-section h2 {
  font-size: 2.5rem;
  color: #ffd700;
  margin-bottom: 1rem;
  text-align: center;
}

.proxy-content {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-bottom: 6rem;
}

.proxy-content.reverse {
  flex-direction: row-reverse;
}

.proxy-info {
  flex: 1;
}

.proxy-graphic {
  flex: 1;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Reuse the graphics from LandingPage.css */
.lte-graphic .rotating-circle {
  width: 200px;
  height: 200px;
  border: 2px solid #ffd700;
  border-radius: 50%;
  position: relative;
  animation: rotate 20s linear infinite;
}

.residential-graphic .globe-effect {
  width: 200px;
  height: 200px;
  border: 2px solid #ffd700;
  border-radius: 50%;
  position: relative;
  background: radial-gradient(circle at center, transparent 30%, rgba(255, 215, 0, 0.1) 70%);
}

@media (max-width: 768px) {
  .proxy-grid {
    grid-template-columns: 1fr;
  }
  
  .dashboard-hero-section h1 {
    font-size: 2.5rem;
  }
} 