.landing-container {
    min-height: 100vh;
    background-color: #000;
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
  
  /* Reset body margins */
  body {
    margin: 0;
    padding: 0;
  }
  
  /* Hero Section */
  .hero-section {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    overflow: hidden;
  }
  
  .hero-content {
    max-width: 600px;
    margin: 0;
    text-align: center;
    z-index: 2;
    padding-bottom: 2rem;
  }
  
  .hero-content h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    background: linear-gradient(45deg, #fff, #ffd700);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .hero-content p {
    font-size: 1.2rem;
    color: #888;
    margin-bottom: 2rem;
  }
  
  .hero-graphics {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
  }
  
  .orbit-circle, .orbit-circle-inner {
    position: absolute;
    border: 2px solid rgba(255, 215, 0, 0.2);
    border-radius: 50%;
    animation: rotate 20s linear infinite;
  }
  
  .orbit-circle {
    width: 300px;
    height: 300px;
  }
  
  .orbit-circle-inner {
    width: 200px;
    height: 200px;
    top: 50px;
    left: 50px;
    animation-direction: reverse;
  }
  
  /* Features Section */
  .features-section {
    padding: 6rem 2rem;
    text-align: center;
  }
  
  .features-section h2 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: #ffd700;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .feature-card {
    background: #111;
    padding: 2rem;
    border-radius: 12px;
    transition: transform 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-10px);
  }
  
  .feature-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  /* Proxy Types Section */
  .proxy-types-section {
    padding: 4rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .proxy-type {
    display: flex;
    align-items: center;
    margin-bottom: 6rem;
    gap: 4rem;
  }
  
  .proxy-type.reverse {
    flex-direction: row-reverse;
  }
  
  .proxy-type-content {
    flex: 1;
  }
  
  .proxy-type-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #ffd700;
  }
  
  .proxy-features {
    list-style: none;
    padding: 0;
    margin: 2rem 0;
  }
  
  .proxy-features li {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .proxy-features li::before {
    content: "→";
    position: absolute;
    left: 0;
    color: #ffd700;
  }
  
  .proxy-type-graphic {
    flex: 1;
    position: relative;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lte-graphic .rotating-circle {
    width: 200px;
    height: 200px;
    border: 2px solid #ffd700;
    border-radius: 50%;
    position: relative;
    animation: rotate 20s linear infinite;
  }
  
  .lte-graphic .rotating-circle::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #ffd700;
    border-radius: 50%;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .residential-graphic .globe-effect {
    width: 200px;
    height: 200px;
    border: 2px solid #ffd700;
    border-radius: 50%;
    position: relative;
    background: radial-gradient(circle at center, transparent 30%, rgba(255, 215, 0, 0.1) 70%);
  }
  
  .residential-graphic .globe-effect::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #ffd700;
    animation: rotate 15s linear infinite;
  }
  
  /* CTA Section */
  .cta-section {
    text-align: center;
    padding: 6rem 2rem;
    background: linear-gradient(to bottom, transparent, rgba(255, 215, 0, 0.1));
  }
  
  .cta-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .cta-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
  }
  
  /* Buttons */
  .cta-button, .learn-more-button, .primary-button, .secondary-button {
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .cta-button, .primary-button {
    background: #ffd700;
    color: #000;
  }
  
  .learn-more-button, .secondary-button {
    background: transparent;
    border: 2px solid #ffd700;
    color: #ffd700;
  }
  
  /* Animations */
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero-content h1 {
      font-size: 2.5rem;
    }
  
    .proxy-type {
      flex-direction: column;
      gap: 2rem;
    }
  
    .proxy-type.reverse {
      flex-direction: column;
    }
  
    .proxy-type-graphic {
      height: 300px;
    }
  
    .cta-buttons {
      flex-direction: column;
    }
  }
  
  /* Add Footer */
  .landing-footer {
    background: #111;
    padding: 3rem 0;
    margin-top: auto;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .footer-section h3 {
    color: #ffd700;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-section li {
    margin-bottom: 0.5rem;
  }
  
  .footer-section a {
    color: #888;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .footer-section a:hover {
    color: #ffd700;
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #222;
    color: #666;
  }