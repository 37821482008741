.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #111;
  border-radius: 12px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.purchase-modal {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #888;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #fff;
}

.modal-content h2 {
  color: #ffd700;
  margin-bottom: 2rem;
  text-align: center;
}

.amount-selector {
  margin-bottom: 2rem;
}

.amount-selector label {
  display: block;
  color: #fff;
  margin-bottom: 1rem;
}

.amount-display {
  color: #ffd700;
  margin-left: 0.5rem;
  font-weight: 600;
}

.price-display {
  text-align: center;
  margin-bottom: 2rem;
}

.price {
  display: block;
  color: #ffd700;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 0.5rem;
}

.purchase-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.cancel-button,
.confirm-button {
  padding: 0.75rem;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button {
  background: #333;
  color: #fff;
  border: none;
}

.cancel-button:hover {
  background: #444;
}

.confirm-button {
  background: #ffd700;
  color: #000;
  border: none;
}

.confirm-button:hover {
  background: #ffed4a;
}

.confirm-button:disabled,
.cancel-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.rotation-selector {
  margin-bottom: 2rem;
}

.rotation-selector label {
  display: block;
  color: #fff;
  margin-bottom: 1rem;
}

.rotation-options {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.rotation-option {
  background: #222;
  border: 1px solid #333;
  border-radius: 6px;
  padding: 1rem;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
}

.rotation-option:hover {
  background: #2a2a2a;
}

.rotation-option.selected {
  background: #ffd700;
  color: #000;
  border-color: #ffd700;
}

.rotation-controls {
  margin-top: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.rotate-button {
  width: 100%;
  padding: 0.75rem;
  background: #2563eb;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.rotate-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.rotation-lock-timer {
  margin-top: 0.5rem;
  text-align: center;
  color: #888;
  font-size: 0.9rem;
}