.style-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .style-modal-content {
    background: #111;
    border-radius: 12px;
    padding: 2rem;
    max-width: 600px;
    width: 90%;
  }
  
  .style-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin: 2rem 0;
  }
  
  .style-button {
    background: #222;
    border: 1px solid #333;
    border-radius: 6px;
    padding: 1rem;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .style-button.selected {
    background: #ffd700;
    color: #000;
    border-color: #ffd700;
  }
  
  .style-submit-button {
    background: #ffd700;
    color: #000;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
  }
  
  .style-submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }